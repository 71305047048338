import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BannerSection from "./BannerSection";

const Home = () => {
    const [paragraph, setParagraph] = useState();
  
    useEffect(() => {
      getdata();
    }, []);
  
    const getdata = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_CMS_URL}about?fields=*.*`
        );
        const data = await res.json();
        setParagraph(data.data[0]);
        console.log(data.data[0]);
      } catch (error) {
        console.log(error);
      }
    };
  return (
    <>
    <BannerSection />

      <div className="welcome">
        <h3>About Dr.Saket Agrawal</h3>
        <div className="wel-row">
          <div className="wel-col ">
          <img src={paragraph?.image?.data?.full_url.replace(
                    "http://",
                    "https://"
                  )} alt="image1" />
          </div>
          <div className="wel-col">
          <p 
            
            dangerouslySetInnerHTML={{
              __html: paragraph?.paragraph1 || "No message available.",
            }}
          />
            
            <Link to="/aboutus">
              <button>Read More</button>
            </Link>
          </div>
        </div>
      </div>

      <div className="speciality">
        <h2>SURGERIES</h2>
        <div className="spec-row spec-row-1">
          <div className="spec-col">
            {/* <div>
              <img src="./img/h1.png" alt="" />
            </div> */}

            <div>
              <h3>Cataract Surgery </h3>
              <p>
                Cataract surgery involves removing the clouded lens and
                replacing it with a clear artificial lens. The artificial lens,
                called an intraocular lens, is positioned in the same place as
                your natural lens.
              </p>
            </div>
          </div>
          <div className="spec-col">
            {/* <div>
              <img src="./img/h2.png" alt="" />
            </div> */}
            <div>
              <h3>Pterygium Surgery </h3>
              <p>
                Pterygium surgery involves removal of the abnormal tissue from
                the sclera and cornea of the eye. Today's techniques offer a
                significantly higher success rate than conventional surgery.
              </p>
            </div>
          </div>
        </div>
        <div className="spec-row">
          <div className="spec-col">
            {/* <div>
              <img src="./img/h3.png" alt="" />
            </div> */}
            <div>
              <h3>DCR Surgery</h3>
              <p>
                Dacryocystorhinostomy (DCR) is a surgery that creates a new path
                for tears to drain between your eyes and your nose.
              </p>
            </div>
          </div>
          <div className="spec-col">
            <div>
              <h3>Lid Mass Excision</h3>
              <p>
                This surgery uses heat to ablate cancerous tumor tissue while
                sealing off blood vessels. Following this, the dead tissue is
                scraped away.
              </p>
            </div>
          </div>
        </div>
        <br />
        {/* <div className="spec-row">
          <div className="spec-col">
            <div>
              <h3>Glue with BCL </h3>
              <p>
                Cyanoacrylate Tissue Adhesive (TA) with Bandage Contact Lens
                (BCL) has become a cornerstone in treating severe corneal
                thinning and perforations ≤2.5 mm in size.
              </p>
            </div>
          </div>
          <div className="spec-col">
            <div>
              <h3>Sutureless</h3>
              <p>
                The sutureless technique did not reduce the rate of restenosis,
                progression of the disease to previously uninvolved pulmonary
                veins, or mortality rate.
              </p>
            </div>
          </div>
        </div> */}
        <div className="extra">
          <h3>Glue with BCL </h3>
          <p>
            Cyanoacrylate Tissue Adhesive (TA) with Bandage Contact Lens (BCL)
            has become a cornerstone in treating severe corneal thinning and
            perforations ≤2.5 mm in size.
          </p>
        </div>
        <div className="imp">
          <span>
            <h2>Cataract Surgery and Lens Transplant Facility Available</h2>
          </span>
        </div>
      </div>

      <section className="our-team">
        <h1>
          Eye Checkup and Surgeries
          <span style={{ color: "#1d4776" }}> With Latest Machines</span>
        </h1>
        <div className="team-row">
          <div className="team-col">
            <img src="images/machine1.jpeg" alt="" />
            <h6>Slit Lamp With Applanation Tonometer </h6>

            {/* <p>(B.H.M.S, M.D)</p> */}
          </div>
          <div className="team-col">
            <img src="images/machine2.jpeg" alt="" />
            <h6> Fully Computerised Glass Prescription</h6>

            {/* <p>B.H.M.S</p> */}
          </div>
          <div className="team-col">
            <img src="images/machine3.jpeg" alt="" />
            <h6>Labomed (USA) Operating Microscope</h6>

            {/* <p>B.H.M.S</p> */}
          </div>
          <div className="team-col">
            <img src="images/machine5.jpeg" alt="" />
            <h6>Modular OT</h6>

            {/* <p>B.H.M.S</p> */}
          </div>
        </div>
      </section>

      <div className="client">
        <img src="" alt="" />
        <div className="client-row">
          <h3>Our Testimonials</h3>
          <h1>What our Patients Say</h1>
        </div>
      </div>

      <div className="benefit">
        <div className="benefit-row ">
          <div className="benefit-col">
            <i class="fa-solid fa-face-smile"></i>
            <h4>Shalu kushwaha</h4>
            <p>
              Doctor is highly experienced . Dr. Saket is very honest with his
              profession and I am very happy with his treatment🙂.
            </p>
          </div>
          <div className="benefit-col">
            <i class="fa-solid fa-face-smile"></i>
            <h4>Amit Tahilani</h4>
            <p>
              Best Opthalmologist in Mandla & Bamhni , Thank u so much dr. Saket
              Agarwal sir for the best treatment.
            </p>
          </div>
          <div className="benefit-col">
            <i class="fa-solid fa-face-smile"></i>
            <h4>Vikas Agrawal</h4>
            <p>
              Best Eye Clinic in Mandla , It was nice experience of visiting
              here and had a perfect solution of any eye diseases.
            </p>
          </div>
          <div className="benefit-col">
            <i class="fa-solid fa-face-smile"></i>
            <h4>Rajesh Jain</h4>
            <p>
              Wonderful office, patient oriented, caring treatment, no matter
              your age. Dr. Agrawal is honest and resourceful.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
